import {URL_DELIMINATOR} from '../constants'

export function isObjectEmptyOrNull(obj: Object | null): boolean {
    if (obj === null) return true;
    return Object.keys(obj).length === 0;
}

export function isArrayEmptyOrNull<T>(value: T[] | null | undefined): boolean {
    return value === null || (Array.isArray(value) && value.length === 0)
}

export function isStringEmptyOrBlank(str: String | null | undefined): boolean {
    if (!str) return true;
    return str?.trim() === ''
}

export const buildQualifierParam = (params: URLSearchParams, field: string, values: Array<any>) => {
    if (values && values.length !== 0) {
        const mappedValues = values.map(item => (item === '' ? '-1' : item));
        params.append(field, mappedValues.join(URL_DELIMINATOR))
    }
}