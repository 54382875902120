import { useState, useEffect, useRef } from 'react';
import { Checkbox, TextField, createFilterOptions, FormControlLabel } from '@mui/material';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import StyledAutocomplete from '../CustomAutocomplete';

const FILTER_DATA_MAX_SIZE = 50;

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  ignoreCase: true,
  limit: FILTER_DATA_MAX_SIZE, 
});


const sortCaseInsensitive = (dataList) => {
  return dataList.sort(function (a, b) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  })
}

export default function MultipleSelectChip(props) {
  const { id, labelId, label } = props;
  const { selectedValues, setSelectedValues } = props;
  const { conditionKey, targetParam } = props;
  const { fetchData } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [tempSelectedValues, setTempSelectedValues] = useState(selectedValues || []);
  const [inputTarget, setInputTarget] = useState('');

  // Dependant filter options
  const [dynamicValues, setDynamicValues] = useState([]);
  const [displayValues, setDisplayValues] = useState([]);
  const [page, setPage] = useState(0)

  const selectionRef = useRef(null)

  const SCROLLER_TOP_PADDING_PX = 5;
  const SCROLLER_BOTTOM_PADDING_PX = selectionRef?.current?.clientHeight + 5;

  useEffect(() => {
    setTempSelectedValues(selectedValues)
  }, [selectedValues])

  const handleOnChange = (event, value) => {
    setSelectedValues(conditionKey, []);  // reset current
    if (value.length === 0) {
      return
    }
    setSelectedValues(
      conditionKey, 
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const onDelete = (value) => {
    const newData = tempSelectedValues.filter(item => item !== value)
    setTempSelectedValues(newData)
    setSelectedValues(conditionKey, newData)
  }

  const onClose = (event) => {
    event.stopPropagation();
    setSelectedValues(conditionKey, tempSelectedValues)
  };

  const handleSelectionOpen = async (event) => {
    event.stopPropagation();
    setIsLoading(true);  
    try {
      await fetchData(targetParam)
        .then((data) => {
          if (data){
            const sortedData = sortCaseInsensitive(data)
            setDynamicValues(sortedData);
          }
        })
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setPage(0)
    }
  }

  const handleScroll = (event) => {
    event.stopPropagation();

    const hasScrolledToBottom = Math.round(event.target.scrollHeight - event.target.scrollTop) === Math.round(event.target.clientHeight);
    const hasScrolledToTop = event.target.scrollTop === 0;

    if (hasScrolledToBottom) {
      setPage(page+1);
      selectionRef.current.scrollTop = SCROLLER_TOP_PADDING_PX;
    } else if (hasScrolledToTop && page > 0) {
      setPage(page-1);
      selectionRef.current.scrollTop = event.target.scrollHeight - SCROLLER_BOTTOM_PADDING_PX;
    }
  }

  useEffect(() => {
    const shouldPagination = dynamicValues.length > FILTER_DATA_MAX_SIZE
    const data = shouldPagination ? 
                    dynamicValues.slice(page * FILTER_DATA_MAX_SIZE, (page + 1) * FILTER_DATA_MAX_SIZE) : 
                    dynamicValues
    setDisplayValues(data)
  }, [page, dynamicValues])

  return (
    <StyledAutocomplete
      clear
      loading={isLoading}
      disableCloseOnSelect
      data-testid={labelId}
      labelId={labelId}
      id={id}
      sx={{
        width: 400,
        overflowY: 'hidden'
      }}
      multiple
      value={tempSelectedValues}
      onChange={handleOnChange}
      onClose={onClose}
      onOpen={handleSelectionOpen}
      inputValue={inputTarget}
      onInputChange={(event, newValue, reason) => {
        if (reason !== 'reset') {
          setInputTarget(newValue)
        }
      }}
      ChipProps={{
        color: 'primary',
        variant: 'outlined',
        sx: [{
          display: 'flex',
          overflow: 'hidden',
          overflowX: 'auto',
          columnGap: 0.5
        }]
      }}
      renderTags={(selected, getTagProps) =>
        selected.map((value, index) =>
          <Chip
            color='primary'
            variant='outlined'
            key={value}
            label={value}
            clickable
            onDelete={() => onDelete(value)}
            {...getTagProps({ index })}
          />
        )
      }
      limitTags={2}
      renderOption={(props, option) => {
        return (
          <Box
            data-testid='select-option-id'
            component='li'
            key={`${option}`}
            {...props}
          >
            <FormControlLabel
              value='end'
              control={<Checkbox checked={tempSelectedValues.includes(option)} />}
              labelPlacement='end'
              label={null}
            />
            {`${option}`}
          </Box>
        )
      }}
      
      filterOptions={(options, state) => {
        setIsLoading(true)
        const filtered = filterOptions(options, state);
        if (isLoading && state?.inputValue !== '' && !filtered.find((option) => option.label === state.inputValue)) {
          const data = dynamicValues.filter((item) => {
            return item.includes(state.inputValue)
          }) 
          setDisplayValues(data || [])
        }
        setIsLoading(false)
        return filtered
      }}
      getOptionLabel={(option) => option}
      options={displayValues}
      renderInput={(params) => {
        return <TextField
          {...params}
          label={label} placeholder={label} />
      }}
      ListboxProps={{
        onScroll: handleScroll,
        ref: selectionRef,
    }}
    />
  );
}
